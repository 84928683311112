import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../styles';
import sponsorme from '../images/sponsorme.png'
import { Container, Typography } from '@material-ui/core'
import LinkLink from '../components/LinkLink'
import BannerImage from '../components/BannerImage'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import OutboundLink from '../components/OutboundLink';

const useStyles = makeStyles(theme => { return {
  ...styles(theme),
  bannerImage: {
    backgroundColor: "#ded3ff",
    marginBottom: "50px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "0"
    },
  }
}})

function WhatIsThisPage() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root}>
        <BannerImage className={classes.bannerImage} image={sponsorme} imageStyles={{maxWidth: "500px"}}>
          <Spacer amount="50px" />
        </BannerImage>
        {/* <div className={classes.bannerImageContainer}>
          <img src={sponsorme} alt="Sponsor Me" style={{height: "100%"}} />
        </div> */}
        <Container maxWidth="md" className={classes.lightContainer}>
          <Spacer amount="25px" />
          <Container maxWidth="sm">
            <Typography variant="body1"><i>"You need to be embarrassed by your first public release.  If you're not embarrassed,
            you're probably waiting too long."</i></Typography>
            <br />
            <div style={{textAlign: "right"}}>
              <Typography variant="body1">Charlie Cleveland, Design Director on <i>Subnautica</i></Typography>
            </div>
          </Container>

          <Spacer amount="50px" />

          <Typography variant="h4">So what is this?</Typography>
          <div style={{margin: "20px 0", borderBottom: "1px solid #d0d0d0"}} />
          <Typography variant="body1">Hi, I'm <OutboundLink href="https://twitter.com/all_iver">Oliver</OutboundLink>! 
          I made the mobile games <OutboundLink href="https://apps.apple.com/us/app/puncho-fighto/id514207451?ls=1#">Puncho 
          Fighto</OutboundLink> and <OutboundLink href="https://apps.apple.com/us/app/particool/id1126135782">Particool</OutboundLink>, as well as a 
          bunch of smaller games like <OutboundLink href="https://www.youtube.com/watch?v=WMGhh3ySnIg">Shitty 
          Voltron</OutboundLink> (with some friends).  I also made the game development tool <OutboundLink 
          href="https://u3d.as/tix">Shapes2D</OutboundLink>.  Jellygon is my 
          company, but I'm the only employee.</Typography>
          <br />

          <Typography variant="body1">I've been working as a programmer for a long time, but now I'm pursuing a passion
          project instead: a video game called <LinkLink href="/starsteaders">Starsteaders</LinkLink>.  My plan for Starsteaders is 
          to create a persistent online universe where you can explore planets, build bases, farm alien 
          plants, visit space stations, and do all that alone or with friends.  The 
          concept is a work in progress, so I'll leave it there for now.</Typography>
          <br /><br />

          <Typography variant="h4">The revenue model</Typography>
          <div style={{margin: "20px 0", borderBottom: "1px solid #d0d0d0"}} />
          <Typography variant="body1">I want to build a community that plays Starsteaders as it develops, 
          and I hope that participating as the game progresses and evolves will be 
          a fun experience that is worth paying for.  That's why I'm releasing the game now, way before it's ready, and 
          offering a pay-what-you-want monthly subscription.  Even if you're not on board now, my hope is that you'll 
          check in occasionally and at some point decide you want to pitch in!</Typography>
          <br />
          <Typography variant="body1">This model is similar to Patreon (except by hosting on my own website I don't 
          have to give them a percentage).  This is not Early Access or Kickstarter, because you're not paying early 
          for a promised end product.  Instead, your money goes to support me on an ongoing basis, in addition to 
          covering server costs for any time you spend playing the game.  There isn't really any 
          planned end product that I am ready to promise at this point, and anything could change.  <b>If this deal does 
          not sound good to you, that's okay</b> - there are other ways you can help, or maybe just check back later 
          to see how things have progressed.
          </Typography>
          <br /><br />

          <Typography variant="h4">Let's make a game!</Typography>
          <div style={{margin: "20px 0", borderBottom: "1px solid #d0d0d0"}} />
          <Typography variant="body1">There are a few ways you can help, if you want!</Typography>
          <br /><br />

          <Typography variant="h5">1) Create a free account</Typography>
          <br />
          <Typography variant="body1">The easiest way to support Starsteaders is to <LinkLink href="/account">create 
          a free account</LinkLink>.  This lets me know you're interested, and is really encouraging for me!  By the way, 
          right now playing Starsteaders is free so after creating an account you 
          can try it out (but don't expect much yet because we are just getting started).</Typography>
          <br /><br />

          <Typography variant="h5">2) Sign up for the newsletter</Typography>
          <br />
          <Typography variant="body1">When you <LinkLink href="/account">create 
          an account</LinkLink>, make sure to subscribe to get email updates in 
          your <LinkLink href="/account">your account options</LinkLink>!  I'll try to make them interesting.</Typography>
          <br /><br />

          <Typography variant="h5">3) Join the community</Typography>
          <br />
          <Typography variant="body1">As a bonus, you 
              can follow <OutboundLink href="https://twitter.com/jellygon">@jellygon</OutboundLink> on Twitter.  Even 
              better, <OutboundLink href="https://discord.gg/U7x8Yum">join the Discord</OutboundLink> where we can chat about 
              how to make Starsteaders awesome.</Typography>
          <br /><br />

          <Typography variant="h5">4) Play Starsteaders</Typography>
          <br />
          <Typography variant="body1">It would be awesome if you could download and play <LinkLink href="/starsteaders">Starsteaders</LinkLink> (you'll 
          need a Jellygon account)!  There's hardly anything there yet, but you can check it out and send me feedback,
          suggestions or bug reports.  Then check back later and see what's new!</Typography>
          <br /><br />

          <Typography variant="h5">5) Become a sponsor</Typography>
          <br />
          <Typography variant="body1">If you agree with my disclaimers above, and you want to support development 
          with a monthly pay-what-you-want subscription, you can do that from <LinkLink href="/account">your account 
          page</LinkLink>.  In the future, only sponsors at a certain level will be able to play Starsteaders.  I'm 
          open to other ideas for sponsor rewards too, as long as it doesn't take too much time away from development.</Typography>
          <br /><br />

          <Typography variant="body1">That's everything - thanks for reading this far!</Typography>
          <Spacer amount="25px" />
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default WhatIsThisPage